<template>
  <div class="home-container">
    <div class='sc-tops'>
      <div class='top-center'>
        <div class="list-img">
          <img :src="data.logo">
        </div>
        <div class="list-info">
          <span>{{ data.storeName }}</span>
          <span>满{{ data.amount }}.10元可用</span>
          <span>
            {{ data.address }}
          </span>
        </div>

      </div>
      <div style="      background: rgb(255, 255, 255);
    width: 93%;
    margin: 0px auto;
    position: relative;
    height: 2.3rem;">
        <div class="sc-step">
          <img src="../../assets/img/sclq.png">
          <!-- <img src="../../assets/img/scjh.png"> -->
          <img src="../../assets/img/scmd.png">
          <img style="margin-right: 0;" src="../../assets/img/scfkm.png">

        </div>
        <div class="sc-step-loop">
          <span></span>
        </div>
        <div style="    position: relative;
    top: 1.55rem;font-size: 0.32rem;display: flex;justify-content: space-evenly;/* position: absolute; *//* margin-top: 1rem; */position: relative;top: 1.55rem;text-align: center;">
          <span style="width: 25%;">领券</span>
          <!-- <span style="width: 25%;"> 激活 </span> -->
          <span style="width: 25%;">线下消费</span>
          <span style="width: 25%;">微信付款码</span>
        </div>

      </div>
      <div class="sc-text" style="margin: 0 auto;color: #000000;padding-left: 0.9rem;">
        温馨提示:同一微信号每月限领取六张代金券。
      </div>
    </div>
    <!-- <div class="sc-text">
      温馨提示:同一微信号每月限领取一张代金券。
    </div> -->
    <div class="sc-text" style="margin-top: 4rem;margin-bottom: 1.5rem;">
      <div v-html="data.content"></div>

    </div>
    <div v-show="!islogin" class="sc-button" @click="clickdetial">立即开通 ·生活会员(10元/月)</div>
    <div v-show="islogin" class="sc-button" @click="clickdetial" v-if="usernum<6">立即领取</div>
  </div>
</template>

<script>
import {
  getReceiveCoupon,
  getCouponInfo,
  getuserInfo,
  haveCoupon
} from '@/api/user'
import {
  Dialog
} from 'vant';
import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'

export default {
  name: 'home',

  props: {},
  data() {
    return {

      active: 0, //被激活的列表
      channels: [], //频道列表
      userType: '',
      isChannelEditShow: false, //频道编辑层
      navlist: [{
        name: '未使用'
      },
      {
        name: '已使用'
      },
      {
        name: '已失效'
      }

      ],
      usernum: '',
      list: [],
      loading: false,
      detailid: '',
      data: [],
      finished: false,
      refreshing: false,
      islogin: false, //判断用户是否登录
    }
  },

  watch: {},
  created() {
    // this.loadChannels()
  },
  mounted() {
    if (this.$store.state.user.token) {
      this.islogin = true

    }
    this.detailid = this.$route.params.detailid
    if (this.$route.params.detailid === undefined) {
      this.detailid = getItem("detailid")
    }
    setItem('detailid', this.detailid)
    this.onLoad()




  },
  methods: {


    async userinfo() {

      const {
        data
      } = await getuserInfo()

      this.userType = data.data.userType
      // this.finished=true
    },
    // 券的次数
    async getusernum() {
      const {
        data
      } = await getuserInfo()
      this.userType = data.data.userType
      const
        datas = await haveCoupon()
      this.usernum = datas.data.data
    },
    // 详情内容
    async onLoad() {
      let params = {
        storeCouponTypeId: this.detailid
      }
      console.log(params)
      const {
        data
      } = await getCouponInfo(params)
      this.data = data.data
      this.userinfo()
      this.getusernum()


      this.record({
        "type": 3,
        "resourceId": this.data.storeId
      })

    },
    async clickdetial() {
      let params = {
        //   "userId":12,
        "busiStoreCouponTypeId": this.detailid,
      }
      if (this.detailid == null) {
        Dialog.alert({
          title: '',
          message: '请重新选择优惠卷',
        }).then(res => {
          this.$router.push({
            name: 'home'
          })
        })
      }
      const {
        data
      } = await getReceiveCoupon(params)
      if (data.code == '100102' || data.code == '100103') {
        Dialog.confirm({
          title: "温馨提示",
          message: "您还不是会员，是否去办理业务",
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          confirmButtonColor: "#FFFFFF",
          messageAlign: "center",
          className: "czq_confirm",
        }).then(() => {
          // 确认执行这里
          location.href = 'https://sn.wxcs.cn/equityh5/#/commonjump?url=pagesB%2FflowActivity%2FmobileSelection'
          console.log('成功')
        }).catch(() => {
          // 取消执行这里
          console.log('失败')
        });
      }
      else if (data.code == '100106') {
        Dialog.alert({
          title: '',
          confirmButtonText: '确定',
          message: '目前活动仅限用户参与，敬请期待！',
        })

      }

      else if (data.code == '200102') {
        Dialog.alert({
          title: '',
          confirmButtonText: '确定',
          message: '尊敬的会员您好，您本月领取资格已用光，可在首页-领取记录中查看本月领取内容。',
        })

      }
      else {
        Dialog.alert({
          title: '',
          confirmButtonText: '确定',
          message: data.msg,
        })
      }






    }


    // if(data.code == )


    // onUpdateActive(index) {
    //   this.active = index
    // }
  }
}
</script>

<style lang="less">
img {
  pointer-events: none;
}

.sc-button {
  background-image: url(../../assets/img/scbutton.png);
  width: 90%;
  margin: 0 auto;
  position: fixed;
  bottom: 0.2rem;
  -webkit-transform: translateZ(0);
  background-size: 100% 100%;
  left: 0;
  right: 0;
  color: #ffffff;
  margin: 0 auto;
  text-align: center;
  box-sizing: border-box;
  font-size: 0.38rem;
  padding: 0.4rem;
}

p {
  margin-bottom: 0.2rem;
}

.sc-text {
  font-size: 0.32rem;
  background: #fff;
  width: 93%;
  margin: 0 auto;
  margin-top: 3rem;
  box-sizing: border-box;
  padding: 0.2rem;
  line-height: 0.5rem;
  border-radius: 0.2rem;
  color: #333333;
}

.sc-step {
  z-index: 999;
  position: absolute;
  // display: flex;
  margin: 0 auto;

  img {
    display: block;
    float: left;
    width: 1.2rem;
    margin: 0.2rem 0.93rem;
  }
}

.sc-step-loop {
  position: absolute;
  height: 0.2rem;
  background: #bbe5fe;
  border-radius: 0.5rem;
  width: 68%;
  /* margin: 0px auto; */
  /* bottom: 1.05rem; */
  top: 0.7rem;
  left: 1rem;
  z-index: 12;

  span {
    display: block;
    width: 26%;
    height: 100%;
    background: #2ca7ee;
    margin-left: 10%;
  }
}

.home-container {
  .sc-tops {
    background-image: url(../../assets/img/sctop.png);
    width: 100%;
    height: 2.7rem;
    padding-top: 0.5rem;
    background-size: 100% 100%;

    .top-center {
      display: flex;
      padding: 0.5rem;
      box-sizing: border-box;
      margin: 0 auto;
      background: #fff;
      width: 93%;

      img {
        display: block;
        width: 1.8rem;
        height: 2rem;
      }

      .list-info {
        padding-left: 0.5rem;

        span {
          display: block;
          font-size: 0.3rem;

          &:nth-child(1) {
            font-size: 0.4rem;
            color: #333333;
            font-family: SourceHanSansCN-Regular;
          }

          &:nth-child(2) {
            padding: 0.1rem 0;
            font-size: 0.3rem;
            background: #bbe5fe;
            border-radius: 0.48rem;
            text-align: center;
            margin: 0.28rem;
            width: 2.5rem;
            color: #0869ab;
            margin-left: 0;
          }

          &:nth-child(3) {
            font-size: 0.3rem;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
